import React, {useState} from 'react'
import {Toast, TOAST_SKIN} from 'wix-ui-tpa/Toast'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Plan} from '../plan'
import s from './desktop.scss'
import {Sidebar} from './sidebar'
import {DesktopProps} from '.'

export const Desktop = ({
  onPlaceClick,
  onRemoveClick,
  onCheckoutClick,
  showAccessibilityMode,
  selectedSeatForDescription,
  setSelectedSeatForDescription,
}: DesktopProps) => {
  const {t} = useTranslation()
  const [forcedPopover, setForcedPopover] = useState(null as string)

  return (
    <div className={s.container}>
      <Sidebar
        onRemoveClick={onRemoveClick}
        onCheckoutClick={onCheckoutClick}
        setForcedPopover={setForcedPopover}
        selectedSeatForDescription={selectedSeatForDescription}
        setSelectedSeatForDescription={setSelectedSeatForDescription}
      />
      <div className={s.planContainer}>
        {showAccessibilityMode ? (
          <Toast className={s.toast} skin={TOAST_SKIN.status}>
            {t('seatings_accessibility_toast')}
          </Toast>
        ) : null}
        <Plan onPlaceClick={onPlaceClick} forcedPopover={forcedPopover} />
      </div>
    </div>
  )
}
