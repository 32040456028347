import * as React from 'react'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {Pagination} from 'wix-ui-tpa/Pagination'
import classNames from 'classnames'
import {TicketsListContainer} from '../../tickets-list-container'
import {Ticket} from '../../ticket'
import {ERROR} from '../../../../../actions/seating'
import {useColors} from '../../../../../hooks/colors'
import {EmptyState} from './empty-state'
import {classes} from './tickets-list.st.css'
import {TicketsListProps} from '.'

const PLACES_PER_PAGE = 50

export const TicketsList = ({
  places,
  addPlaceToBasket,
  setError,
  ticketLimitReached,
  setForcedPopover,
  selectPrice,
  selectLocation,
}: TicketsListProps) => {
  const {darkColor} = useColors()
  const [currentPage, setCurrentPage] = React.useState(1)
  const placesCount = places.length

  const handlePlaceClick = (clickedPlace: PlaceWithTicketInfo, selected: boolean) => {
    if (ticketLimitReached && !selected) {
      setError(ERROR.TICKET_LIMIT_REACHED)
    } else {
      addPlaceToBasket({placeId: clickedPlace.id, count: selected ? 0 : 1})
    }
  }

  const handleEmptyStateButtonClick = () => {
    selectPrice(null)
    selectLocation(null)
  }

  return (
    <TicketsListContainer className={!placesCount ? classes.emptyState : undefined}>
      {placesCount ? (
        places
          .slice((currentPage - 1) * PLACES_PER_PAGE, currentPage * PLACES_PER_PAGE)
          .map((place, index) => (
            <Ticket
              key={`${index}-${place.id}`}
              lastTicket={index === placesCount - 1}
              place={place}
              onClick={clickedPlace => handlePlaceClick(clickedPlace, place.inBasket)}
              setForcedPopover={setForcedPopover}
            />
          ))
      ) : (
        <EmptyState onButtonClick={handleEmptyStateButtonClick} />
      )}
      {placesCount > PLACES_PER_PAGE ? (
        <div className={classes.paginationContainer}>
          <Pagination
            className={classNames(classes.pagination, {[classes.paginationNotWired]: !darkColor})}
            currentPage={currentPage}
            totalPages={Math.ceil(placesCount / PLACES_PER_PAGE)}
            onChange={({page}) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </TicketsListContainer>
  )
}
