import {hasSeatingPlan, isRegistrationClosedVisible} from '@wix/wix-events-commons-statics'
import {getText} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {Header as Presentation} from './header'
import {HeaderOwnProps, HeaderRuntimeProps} from './interfaces'

export const mapRuntime = ({state}: DetailsPageAppProps): HeaderRuntimeProps => ({
  title: getText('ticketsTitleText', state.component.settings),
  withSeatingPlan: hasSeatingPlan(state.event),
  registrationClosed: isRegistrationClosedVisible(state.event),
})

export const Header = connect<HeaderOwnProps, HeaderRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
