import {Place, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {PlaceWithTicketInfo, saleScheduled} from '@wix/wix-events-commons-statics'
import {Modal} from '../modal'
import {getPlaceInfo, getPlaceQuantity} from '../../../selectors/seating'
import {ERROR} from '../../../actions/seating'
import {getTicketById} from '../../../selectors/tickets'
import {Loader} from './loader'
import {ErrorModal} from './error-modal'
import {Mobile} from './mobile'
import {Desktop} from './desktop'
import {classes} from './seating-plan.st.css'
import {SeatingPlanProps} from '.'

export const SeatingPlan = ({
  addPlaceToBasket,
  checkout,
  loading,
  error,
  placesInBasket,
  showAccessibilityMode,
  chooseSeatMode,
  setShowAccessibilityMode,
  selectedPlace,
  unselectPlace,
  selectPlace,
  setError,
  ticketLimitReached,
  tickets,
  places,
}: SeatingPlanProps) => {
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<PlaceWithTicketInfo>(null)

  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const desktop = !mobile

  useEffect(() => {
    if (chooseSeatMode) {
      window.parent.postMessage({placesInBasket}, '*')
    }
  }, [placesInBasket])

  const handleAddClick = useCallback(
    (amount: number = 1) => {
      const currentCount = getPlaceQuantity(placesInBasket, selectedPlace.id)
      const area = selectedPlace.elementType === Type.AREA

      addPlaceToBasket({
        placeId: selectedPlace.id,
        count: area && mobile ? currentCount + amount : amount,
      })
    },
    [selectedPlace],
  )

  const handleCheckout = useCallback(() => checkout(), [checkout])

  const onPlaceClick = useCallback(
    (place: Place) => {
      const currentCount = getPlaceQuantity(placesInBasket, place.id)
      const area = place.elementType === Type.AREA
      const ticketLimitReachedError = (ticketLimitReached && area) || (ticketLimitReached && currentCount === 0)

      const handlePlaceSelectUnselect = () =>
        selectedPlace?.id === place.id ? unselectPlace({placeId: place.id}) : selectPlace({placeId: place.id, mobile})

      const handleAddPlaceToBasket = () => {
        const placeWithInfo = getPlaceInfo(places, place.id)
        const ticket = getTicketById(tickets, placeWithInfo.ticketId)

        if (saleScheduled(ticket)) {
          return
        } else {
          setSelectedSeatForDescription(null)
          if (area) {
            mobile ? handlePlaceSelectUnselect() : addPlaceToBasket({placeId: place.id, count: currentCount + 1})
          } else {
            addPlaceToBasket({placeId: place.id, count: currentCount ? 0 : 1})
          }
        }
      }

      if (ticketLimitReachedError) {
        setError(ERROR.TICKET_LIMIT_REACHED)
      } else {
        desktop || showAccessibilityMode || currentCount ? handleAddPlaceToBasket() : handlePlaceSelectUnselect()
      }
    },
    [showAccessibilityMode, placesInBasket, selectedPlace],
  )

  const onRemoveCLick = useCallback(
    (place: PlaceWithTicketInfo) => {
      const currentCount = getPlaceQuantity(placesInBasket, place.id)
      addPlaceToBasket({placeId: place.id, count: currentCount - 1})
    },
    [placesInBasket],
  )

  const mobileTabs = useMemo(
    () => [{title: t('seatingMapModal_mapTab_title')}, {title: t('seatingMapModal_listTab_title')}],
    [t],
  )

  const onTabClick = useCallback(() => setShowAccessibilityMode(!showAccessibilityMode), [showAccessibilityMode])

  const onCloseClick = useCallback(() => {
    if (selectedPlace?.id) {
      unselectPlace({placeId: selectedPlace.id})
    }
  }, [selectedPlace])

  return (
    <Modal
      className={classNames(
        desktop && !chooseSeatMode && classes.desktopModal,
        chooseSeatMode && classes.hideCloseButton,
      )}
      closeButtonAsButton={desktop}
      dataHook={DH.SEATINGS_MODAL}
      title={mobile ? t('seatingMapModal_title') : undefined}
      withoutPadding={desktop}
      tabs={mobile ? mobileTabs : undefined}
      activeTabIndex={showAccessibilityMode ? 1 : 0}
      onTabClick={onTabClick}
    >
      <ErrorModal type={error} />
      {loading ? <Loader /> : null}
      {mobile ? (
        <Mobile
          onRemoveClick={onRemoveCLick}
          onCheckoutClick={handleCheckout}
          onPlaceClick={onPlaceClick}
          onCloseClick={onCloseClick}
          onAddClick={handleAddClick}
        />
      ) : (
        <Desktop
          onPlaceClick={onPlaceClick}
          onRemoveClick={onRemoveCLick}
          onCheckoutClick={handleCheckout}
          showAccessibilityMode={showAccessibilityMode}
          selectedSeatForDescription={selectedSeatForDescription}
          setSelectedSeatForDescription={setSelectedSeatForDescription}
        />
      )}
    </Modal>
  )
}
